import { render, staticRenderFns } from "./LiveSupport.vue?vue&type=template&id=06e6d1ca&scoped=true&"
import script from "./LiveSupport.vue?vue&type=script&lang=js&"
export * from "./LiveSupport.vue?vue&type=script&lang=js&"
import style0 from "./LiveSupport.vue?vue&type=style&index=0&id=06e6d1ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e6d1ca",
  null
  
)

export default component.exports